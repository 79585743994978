/* You can add global styles to this file, and also import other style files */


/* html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
} */

@import "scss/_theme";
@tailwind base;
@tailwind components;
@tailwind utilities;